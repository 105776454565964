import React from "react";
import { useGetBillingHistoryQuery } from "../store/userApi";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
} from "@mui/material";
import { format } from "date-fns";
import LoadingPage from "../common/LoadingPage";
import ErrorWidget from "../error-helpers/ErrorWidget";

const BillingHistoryPage: React.FC = () => {
  // Fetch billing history
  const {
    data: billingHistory,
    error,
    isLoading,
  } = useGetBillingHistoryQuery();

  // Format the amount (Stripe returns it in the smallest currency unit, e.g., cents)
  const formatAmount = (amount: number, currency: string) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency.toUpperCase(),
    }).format(amount / 100); // Convert cents to dollars, for example

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <ErrorWidget error={error} />;
  }

  if (!billingHistory || billingHistory.length === 0) {
    return <Typography>No billing history found.</Typography>;
  }

  return (
    <Box sx={{ padding: 4 }}>
      {" "}
      {/* Add padding around the entire component */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Date</strong>
              </TableCell>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell>
                <strong>Amount</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {billingHistory.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>
                  {format(new Date(invoice.created * 1000), "PP")}
                </TableCell>
                <TableCell>{invoice.description}</TableCell>
                <TableCell>
                  {formatAmount(invoice.amount, invoice.currency)}
                </TableCell>
                <TableCell>{invoice.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BillingHistoryPage;
