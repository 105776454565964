import React, { useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  Box,
  Typography,
  Button,
  TextField,
  Container,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LockOutlined } from "@mui/icons-material";
import { otterTheme } from "../../theme";
import { useNavigate } from "react-router-dom";
import {
  useCreateUserWithSubscriptionMutation,
  UserSubscriptionCreateArgs,
} from "../../store/userApi";
import LoadingPage from "../../common/LoadingPage";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { getErrorMessage } from "../../error-helpers/errorMessage";
import { getBlackTextLogo } from "../../common/commonWidgets";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userSlice";

const CheckoutForm: React.FC = () => {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [billingCountry, setBillingCountry] = useState("United States");
  const [zip, setZip] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const dispatch = useDispatch();
  const [
    createUserWithSubscription,
    { isLoading: creatingUserWithSubscription },
  ] = useCreateUserWithSubscriptionMutation();

  const handlePurchaseButtonClicked = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements || !agreedToTerms) {
      return;
    }

    setIsProcessing(true);
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (cardNumberElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: `${firstName} ${lastName}`,
        },
      });

      if (error) {
        setErrorMessage(error.message || "Payment failed");
        showErrorToast(`Could not create payment. ${getErrorMessage(error)}`);
        setIsProcessing(false);
        return;
      }
      try {
        const userCreateSubscriptionArgs: UserSubscriptionCreateArgs = {
          firstName,
          lastName,
          password,
          email,
          paymentMethodId: paymentMethod.id!,
        };

        const newUser = await createUserWithSubscription(
          userCreateSubscriptionArgs,
        ).unwrap();
        dispatch(setUser(newUser));
        setIsProcessing(false);
        setErrorMessage(null);
        showSuccessToast("Successfully signed you up!");
        navigate("/dashboard");
      } catch (error) {
        showErrorToast(getErrorMessage(error));
      }
    }
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>{getBlackTextLogo()}</Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 4,
        }}
      >
        <Box
          sx={{
            width: "40%",
            backgroundColor: "#f9f9f9",
            p: 4,
            borderRadius: 4,
          }}
        >
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Upgrade to Tranzcribe Business
          </Typography>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            Billing cycle
          </Typography>
          <Button variant="outlined" fullWidth sx={{ mb: 2 }}>
            Monthly - $19.99 per user/month
          </Button>

          <Typography variant="body1" color="textSecondary" gutterBottom>
            Number of users
          </Typography>
          <TextField select fullWidth defaultValue={1} sx={{ mb: 2 }}>
            {[1, 2, 3, 4].map((num) => (
              <MenuItem key={num} value={num}>
                {num} Users
              </MenuItem>
            ))}
          </TextField>

          <Typography variant="body1" color="textSecondary">
            Due today: $0
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 2 }}>
            Subscription starts: $19.99 on <b>Oct 24, 2024</b>
          </Typography>
        </Box>

        {/* Payment Form */}
        <Box
          sx={{
            width: "50%",
            backgroundColor: "#ffffff",
            p: 4,
            borderRadius: 4,
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography variant="h5" align="center" gutterBottom>
            Create your account
          </Typography>
          <Typography
            variant="body1"
            align="right"
            sx={{ mb: 2 }}
            component="a"
            href="/login"
            style={{
              textDecoration: "none",
              color: otterTheme.palette.primary.main,
            }}
          >
            Already have an account? Log in
          </Typography>

          {/* Form Start */}
          <form onSubmit={handlePurchaseButtonClicked}>
            {/* Email */}
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Email"
                type="email"
                fullWidth
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>

            {/* Password */}
            <Box sx={{ mb: 3 }}>
              <TextField
                label="Password"
                type="password"
                fullWidth
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {/* Name */}
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <TextField
                label="First Name"
                fullWidth
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <TextField
                label="Last Name"
                fullWidth
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Box>

            {/* Payment Information */}
            <Typography variant="h6" gutterBottom sx={{ mt: 4, mb: 2 }}>
              Payment
            </Typography>

            <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
              <LockOutlined sx={{ mr: 1 }} />
              <Typography variant="body1" fontWeight="bold">
                Card Information
              </Typography>
            </Box>

            {/* Card Number */}
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  padding: "10px",
                  border: "1px solid #c4c4c4",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                }}
              >
                <CardNumberElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: otterTheme.palette.text.primary,
                        "::placeholder": {
                          color: otterTheme.palette.text.secondary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            {/* Expiration Date and CVC */}
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <Box
                sx={{
                  padding: "10px",
                  border: "1px solid #c4c4c4",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  flex: 1,
                }}
              >
                <CardExpiryElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: otterTheme.palette.text.primary,
                        "::placeholder": {
                          color: otterTheme.palette.text.secondary,
                        },
                      },
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  padding: "10px",
                  border: "1px solid #c4c4c4",
                  borderRadius: "4px",
                  backgroundColor: "#fff",
                  flex: 1,
                }}
              >
                <CardCvcElement
                  options={{
                    style: {
                      base: {
                        fontSize: "16px",
                        color: otterTheme.palette.text.primary,
                        "::placeholder": {
                          color: otterTheme.palette.text.secondary,
                        },
                      },
                    },
                  }}
                />
              </Box>
            </Box>

            {/* Billing Country */}
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <TextField
                label="Billing Country"
                select
                fullWidth
                value={billingCountry}
                onChange={(e) => setBillingCountry(e.target.value)}
              >
                <MenuItem value="United States">United States</MenuItem>
                <MenuItem value="Canada">Canada</MenuItem>
                <MenuItem value="United Kingdom">United Kingdom</MenuItem>
              </TextField>
              <TextField
                label="ZIP Code"
                fullWidth
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
            </Box>

            {/* Terms Checkbox */}
            <Box sx={{ mb: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agreedToTerms}
                    onChange={(e) => setAgreedToTerms(e.target.checked)}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline", color: "primary.main" }}
                    onClick={() => {
                      navigate("/terms");
                    }}
                  >
                    I agree to the Terms & Conditions
                  </Typography>
                }
              />
            </Box>

            {/* Submit Button */}
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={!stripe || isProcessing || creatingUserWithSubscription}
              sx={{ backgroundColor: otterTheme.palette.primary.main }}
            >
              Sign Up & Subscribe
            </Button>

            {errorMessage && (
              <Typography color="error" variant="body2" sx={{ mt: 2 }}>
                {errorMessage}
              </Typography>
            )}
          </form>
          {(isProcessing || creatingUserWithSubscription) && <LoadingPage />}
        </Box>
      </Box>
    </Container>
  );
};

const SignUpMonthlySubscription: React.FC = () => <CheckoutForm />;

export default SignUpMonthlySubscription;
