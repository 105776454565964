import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  SummaryTypes,
  useGetSummaryPromptMutation,
  useProcessAudioMutation,
} from "../store/apiSlice";
import { showErrorToast, showWarningToast } from "../toastUtils";
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Button,
  ThemeProvider,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LoadingPage from "../common/LoadingPage";
import { getErrorMessage } from "../error-helpers/errorMessage";
import JsPDF from "jspdf";
import { getWhiteTextBetaLogo } from "../common/commonWidgets";
import { formatElapsedTime } from "../common/commonFuncs";
import logo from "../images/tranzcribe-logo-text-black.png";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  countdownBoxStyles,
  countdownTypographyStyles,
  tabStyles,
} from "./styles";
import { logAnalyticsEvent } from "../config/firebase";
import { logPosthogEvent } from "../common/posthogUtils";
import { darkTheme } from "../theme";
import PromptTab from "./prompt-tab/PromptTab";
import { isMobile } from "react-device-detect";
import DocumentIcon from "@mui/icons-material/Description";
import TextMessageIcon from "@mui/icons-material/Chat";
import EmailIcon from "@mui/icons-material/Email";
import SummarizationDisplayTab from "./SummarizationDisplayTab";

const AudioRecorder: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [, setAudioUrl] = useState<string | null>(null);
  const [transcribedText, setTranscribedText] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(-1);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [language] = useState<string>("en");
  const [showCompletedIcon, setShowCompletedIcon] = useState(false);
  const elapsedTimeRef = useRef(0);
  const [summarizedText, setSummarizedText] = useState<string | null>(null);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioBlobRef = useRef<Blob | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [getSummaryPrompt, { isLoading: loadingSummary }] =
    useGetSummaryPromptMutation();
  const [processAudio, { isLoading }] = useProcessAudioMutation();

  const user = useSelector((state: RootState) => state.user);

  const handleProcessAudio = useCallback(async () => {
    if (!audioBlobRef.current) {
      showWarningToast("No audio recorded. Please record audio first.");
      return;
    }
    const formData = new FormData();
    formData.append("audio", audioBlobRef.current, "recording");
    formData.append("language", language);
    formData.append("videoLength", elapsedTimeRef.current.toString());
    try {
      const response = await processAudio(formData).unwrap();
      setTranscribedText(response.transcribedText);
      const summaryText = await getSummaryPrompt({
        text: response.transcribedText,
        type: SummaryTypes.SUMMARY,
      }).unwrap();
      setSummarizedText(summaryText.gptTextResponse);
    } catch (error) {
      showErrorToast(`Failed to process audio: ${getErrorMessage(error)}`);
    }
  }, [processAudio, language]);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;

      let mimeType: string = "";
      if (MediaRecorder.isTypeSupported("audio/webm")) {
        mimeType = "audio/webm";
      } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        mimeType = "audio/mp4";
      } else if (MediaRecorder.isTypeSupported("audio/mpeg")) {
        mimeType = "audio/mpeg";
      } else {
        showErrorToast("No supported audio format found for recording.");
        return;
      }
      logAnalyticsEvent("startRecording", {
        mimeType,
      });
      logPosthogEvent(
        "startRecording",
        {
          mimeType,
        },
        user.user?.email,
      );

      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
        audioBlobRef.current = audioBlob;

        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        audioChunksRef.current = [];

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }

        logAnalyticsEvent("stoppedRecording", {
          mimeType,
        });
        logPosthogEvent(
          "stoppedRecording",
          {
            mimeType,
          },
          user.user?.email,
        );

        clearRecordingTimer();
        handleProcessAudio();
        setShowCompletedIcon(true);
        setTimeout(() => setShowCompletedIcon(false), 2000);
        setHasRecorded(true);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setElapsedTime(0);
      elapsedTimeRef.current = 0;
      startRecordingTimer();
    } catch (error) {
      showErrorToast(
        `Could not access the microphone. Please check your permissions (${getErrorMessage(error)}).`,
      );
    }
  }, [handleProcessAudio]);

  const startCountdown = () => {
    setCountdown(3);
  };

  useEffect(() => {
    if (countdown === null) return;

    if (countdown > 0) {
      const timer = setTimeout(
        () => setCountdown((prev) => (prev ?? 0) - 1),
        1000,
      );
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      startRecording();
      setCountdown(null);
    }
  }, [countdown, startRecording]);

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      clearRecordingTimer();
      setIsRecording(false);
    }
  };

  const startRecordingTimer = () => {
    recordingTimerRef.current = setInterval(() => {
      elapsedTimeRef.current += 1;
      setElapsedTime(elapsedTimeRef.current); // This updates UI without affecting `elapsedTimeRef`
    }, 1000);
  };

  const clearRecordingTimer = () => {
    if (recordingTimerRef.current) {
      clearInterval(recordingTimerRef.current);
      recordingTimerRef.current = null;
    }
  };

  const handleDownload = async (text: string | null, filename: string) => {
    if (!text) return;

    const doc = new JsPDF();
    doc.setFontSize(12);

    // Add the logo at the top (centered)
    const imgWidth = 75; // Adjust width as needed
    const imgHeight = 35; // Adjust height as needed
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginX = (pageWidth - imgWidth) / 2; // Center the image horizontally

    doc.addImage(logo, "PNG", marginX, 10, imgWidth, imgHeight);

    // Add the text below the logo
    const lines = doc.splitTextToSize(text, 180);
    doc.text(lines, 10, imgHeight + 20);

    // Add black outline around the PDF page
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setLineWidth(0.2);
    doc.setDrawColor(0, 0, 0); // Black color for the outline
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // Rectangle outline

    logAnalyticsEvent("handleDownload", {
      filename,
    });
    doc.save(filename.endsWith(".pdf") ? filename : `${filename}.pdf`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    logAnalyticsEvent("handlePromptChange", {
      activeTab: newValue,
    });
    setActiveTab(newValue);
    setSummarizedText(null);
  };

  const handleMicClick = () => {
    if (hasRecorded) {
      setHasRecorded(false);
      setTranscribedText(null);
      setActiveTab(-1);
      startCountdown();
    } else {
      startCountdown();
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        height={"95vh"}
        sx={{
          textAlign: "center",
          color: "#333333",
          backgroundColor: darkTheme.palette.background.default,
        }}
      >
        {/* Updated this Box to adjust the layout */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isRecording ? "row" : "column"}
          width="100%"
        >
          {getWhiteTextBetaLogo()}
        </Box>

        {/* The rest of your code remains mostly the same, with minor adjustments */}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleMicClick}
          sx={{ textAlign: "center", mb: 4, cursor: "pointer", mt: 4 }}
        >
          {hasRecorded && (
            <Button color="primary" onClick={handleMicClick} sx={tabStyles}>
              <Box color={"#ffffff"}>
                <MicIcon />
                <Typography>Record a new message</Typography>
              </Box>
            </Button>
          )}
        </Box>

        {!hasRecorded && countdown === null && (
          <Box mt={20} mb={20}>
            <IconButton
              color={isRecording ? "secondary" : "primary"}
              onClick={isRecording ? stopRecording : startCountdown}
              sx={{
                mb: 2,
                color: "#FFFFFF",
                transform: "scale(5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                width: "fit-content",
                animation: isRecording ? "pulsate 2s infinite" : "none",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
                "@keyframes pulsate": {
                  "0%": {
                    transform: "scale(5)",
                    color: "#FFFFFF",
                  },
                  "50%": {
                    transform: "scale(5.1)",
                    color: "red",
                  },
                  "100%": {
                    transform: "scale(5)",
                    color: "#FFFFFF",
                  },
                },
              }}
            >
              {isRecording ? (
                <StopIcon fontSize="large" />
              ) : (
                <MicIcon
                  fontSize="large"
                  sx={{
                    color: "#FFFFFF",
                    padding: "4px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
                  }}
                />
              )}
            </IconButton>
          </Box>
        )}

        {countdown !== null ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={countdownBoxStyles}
            onClick={() => {
              setCountdown(null);
            }}
          >
            <Typography variant="h1" sx={countdownTypographyStyles}>
              {countdown}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                fontWeight: "medium",
                mt: 1,
              }}
            >
              Stop
            </Typography>
          </Box>
        ) : isRecording ? (
          <Typography
            variant="h6"
            sx={{ mt: 1, color: "red", fontSize: "32px" }}
          >
            {formatElapsedTime(elapsedTime)}
          </Typography>
        ) : showCompletedIcon ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <ThumbUpIcon color="success" sx={{ mr: 1 }} />
            <Typography variant="h6" sx={{ color: "green" }}>
              Completed!
            </Typography>
          </Box>
        ) : null}

        {isLoading && <LoadingPage />}

        {transcribedText && (
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              textColor="inherit"
              scrollButtons={isMobile}
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              {user.user?.prompts.map((prompt, index) => {
                // Determine the icon for the tab
                const TabIcon =
                  index === 2
                    ? DocumentIcon
                    : index === 1
                      ? TextMessageIcon
                      : index === 0
                        ? EmailIcon
                        : null;

                return (
                  <Tab
                    key={prompt.id}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        {TabIcon && <TabIcon />}
                        {prompt.title}
                      </Box>
                    }
                    sx={tabStyles}
                  />
                );
              })}
            </Tabs>
            {user.user?.prompts.length === 0 && (
              <Typography>You do not have any prompts!</Typography>
            )}
            {user.user?.prompts.map((prompt, index) =>
              activeTab === index ? (
                <PromptTab
                  key={prompt.id}
                  prompt={prompt}
                  inputText={transcribedText}
                  handleDownload={handleDownload}
                />
              ) : (
                <Box key={prompt.id} />
              ),
            )}
            {summarizedText !== null && (
              <SummarizationDisplayTab text={summarizedText} />
            )}
          </Box>
        )}
        {loadingSummary && <LoadingPage />}
      </Box>
    </ThemeProvider>
  );
};

export default AudioRecorder;
