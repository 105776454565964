import React from "react";
import { Box, Container, Typography, Paper, Divider } from "@mui/material";
import { getBlackTextBetaLogo } from "../../common/commonWidgets";

const TermsAndConditionsPage: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4, paddingBottom: 4 }}>
      {getBlackTextBetaLogo()}
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Terms and Conditions
        </Typography>
        <Divider sx={{ mb: 4 }} />
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            1. Overview
          </Typography>
          <Typography variant="body1" paragraph>
            These terms of use ("AI Terms of Use") govern your use of any AI
            services and tools offered by Tranzcribe or its affiliates (“AI
            Services”) and apply in addition to Tranzcribe’s Universal Terms of
            Service Agreement, Privacy Policy, and all product-specific terms.
            If you use the AI Services to create a website or content for a
            website, you agree to the terms of Tranzcribe’s Website Services
            Agreement.
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            2. Content
          </Typography>
          <Typography variant="body1" paragraph>
            Tranzcribe claims no intellectual property rights in any user input
            that you provide to the AI Services or any output generated by the
            AI Services. Tranzcribe also does not review such inputs or outputs,
            including text, logos, names, or slogans, for accuracy, bias, or
            intellectual property rights clearance. You are solely responsible
            for ensuring that you have all rights necessary (including
            trademark, copyright, and other intellectual property rights) to
            provide your inputs to the AI Services.
          </Typography>
          <Typography variant="body1" paragraph>
            Tranzcribe makes no representation, warranty, or guarantee as to the
            accuracy or reliability of the AI Services or whether such output
            may infringe on third-party intellectual property rights. You should
            review the output accordingly, and your use thereof is at your sole
            risk.
          </Typography>
          <Typography variant="body1" paragraph>
            Outputs may not be unique across users, and the AI Services may
            generate the same or similar outputs for other users.
          </Typography>
          <Typography variant="body1" paragraph>
            Tranzcribe may use technology provided by third-party service
            providers to provide AI Services. You authorize Tranzcribe and such
            third-party service providers to store and use your input for the
            purposes of providing you with the AI Services, to review inputs and
            outputs for abuse or misuse, and to develop and improve the services
            and products of Tranzcribe and such service providers, including as
            part of the design, training, and development process for machine
            learning models.
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            3. AI Rules of Conduct
          </Typography>
          <Typography variant="body1" paragraph>
            All uses of our AI Services must comply with Universal Terms of
            Service, including the General Rules of Conduct set forth in Section
            5. In addition, you hereby represent and agree that you will not
            use, nor authorize anyone to use on your behalf, the AI Services in
            a manner that is illegal, promotes illegal activity, or infringes on
            intellectual property rights.
          </Typography>
          {/* Additional detailed terms omitted for brevity */}
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            4. Disclaimers
          </Typography>
          <Typography variant="body1" paragraph>
            YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT OUTPUTS OF THE AI
            SERVICES HAVE NOT BEEN REVIEWED FOR ACCURACY, BIAS, OR INTELLECTUAL
            PROPERTY RIGHTS CLEARANCE. Tranzcribe MAKES NO REPRESENTATION,
            WARRANTY, OR GUARANTEE AS TO THE ACCURACY OR RELIABILITY OF THE AI
            SERVICES.
          </Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h5" gutterBottom>
            5. Limitation on Liability
          </Typography>
          <Typography variant="body1" paragraph>
            IN NO EVENT SHALL Tranzcribe, ITS OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS, OR ANY THIRD-PARTY SERVICE PROVIDERS, BE LIABLE TO YOU OR
            ANY OTHER PERSON OR ENTITY FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER.
          </Typography>
          <Typography variant="body1" paragraph>
            IN ADDITION, YOU SPECIFICALLY ACKNOWLEDGE AND AGREE THAT IN NO EVENT
            SHALL Tranzcribe’S TOTAL AGGREGATE LIABILITY EXCEED $10,000.00 U.S.
            DOLLARS.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsAndConditionsPage;
