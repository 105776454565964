import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../store/store"; // Adjust this based on your store
import { setUser } from "../../store/userSlice";
import { useGetUserAttributesQuery } from "../../store/userApi";
import LoadingPage from "../../common/LoadingPage";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const dispatch = useDispatch();
  const userState = useSelector((state: RootState) => state.user);

  const { data: user, isLoading, error } = useGetUserAttributesQuery();

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    }
  }, [user, dispatch]);

  if (userState.user) {
    return <>{children}</>;
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  if (error) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  if (!user) {
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
