import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  useGetSubscriptionDetailsQuery,
  useCancelSubscriptionMutation,
  useCreateSubscriptionMutation,
  useReactivateSubscriptionMutation,
} from "../../store/userApi";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import LockOutlined from "@mui/icons-material/LockOutlined";
import LoadingPage from "../../common/LoadingPage";
import ErrorWidget from "../../error-helpers/ErrorWidget";
import { otterTheme } from "../../theme";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { getErrorMessage } from "../../error-helpers/errorMessage";

const SubscriptionPage = () => {
  const stripe = useStripe();
  const elements = useElements();
  const user = useSelector((state: RootState) => state.user.user);
  const { data: subscriptionDetails, isLoading } =
    useGetSubscriptionDetailsQuery();
  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const [createSubscription, { isLoading: isCreating }] =
    useCreateSubscriptionMutation();
  const [reactivateSubscription, { isLoading: isReactivatingSubscription }] =
    useReactivateSubscriptionMutation();

  const [billingCountry, setBillingCountry] = useState("United States");
  const [zip, setZip] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [openDialog, setOpenDialog] = useState(false);
  const [openSubscribeDialog, setOpenSubscribeDialog] = useState(false); // For subscribe confirmation

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const currentDate = new Date(); // Current date

  const handleSubscribe = async () => {
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          address: {
            country: billingCountry,
            postal_code: zip,
          },
        },
      });

      if (error) {
        setErrorMessage(error.message || "Failed to create payment method.");
        return;
      }

      if (paymentMethod) {
        await createSubscription({
          paymentMethodId: paymentMethod.id,
        }).unwrap();
        showSuccessToast(
          "Subscription created successfully! You now have PRO benefits!",
        );
        setOpenSubscribeDialog(false); // Close dialog after success
      }
    } catch (error) {
      console.error("Failed to create subscription:", error);
      showErrorToast(
        `Failed to create subscription. ${getErrorMessage(error)}`,
      );
    }
  };

  const handleCancelSubscription = async () => {
    if (subscriptionCanBeReactivated) {
      try {
        await reactivateSubscription().unwrap();
        showSuccessToast(
          "Success! Thank you for subscribing to Tranzcribe PRO again!",
        );
      } catch (error) {
        showErrorToast(
          `Failed to reactivate subscription: ${getErrorMessage(error)}`,
        );
      }
    } else {
      try {
        await cancelSubscription().unwrap();
        setOpenDialog(false);
        showSuccessToast(
          "Subscription cancelled successfully. We hope you enjoy your PRO benefits.",
        );
      } catch (error) {
        showErrorToast(
          `Failed to cancel subscription. ${getErrorMessage(error)}`,
        );
      }
    }
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenSubscribeDialog = () => {
    setOpenSubscribeDialog(true);
  };

  const handleCloseSubscribeDialog = () => {
    setOpenSubscribeDialog(false);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!user) {
    return <ErrorWidget error={"No User found. Please refresh."} />;
  }

  // Logic to check if subscription can be reactivated
  const subscriptionCanBeReactivated =
    !user.hasActiveSubscription && currentDate < new Date(user.subscribedUntil);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Card sx={{ maxWidth: 800, width: "100%", p: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom align="center">
            Subscription Status
          </Typography>

          {user.stripeSubscriptionId ? (
            <>
              <Typography variant="body1">
                Your subscription is{" "}
                <strong>
                  {user.hasActiveSubscription
                    ? "active"
                    : `cancelled, but you still have PRO benefits until ${format(
                        new Date(user.subscribedUntil),
                        "MMMM dd, yyyy",
                      )}`}
                </strong>
                .
              </Typography>
              {subscriptionDetails && (
                <>
                  <Typography variant="body2">
                    Next billing date:{" "}
                    <strong>
                      {format(
                        new Date(subscriptionDetails.currentPeriodEnd),
                        "MMMM dd, yyyy",
                      )}
                    </strong>
                  </Typography>
                  <Typography variant="body2">
                    Subscription Plan: <strong>Tranzcribe PRO</strong>
                  </Typography>
                </>
              )}
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenDialog}
                  disabled={isCanceling}
                  fullWidth
                >
                  {subscriptionCanBeReactivated
                    ? "Reactivate Subscription"
                    : "Cancel Subscription"}
                </Button>
              </Box>
              {(isCanceling || isReactivatingSubscription) && <LoadingPage />}

              {/* Confirmation Dialog */}
              <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                fullScreen={fullScreen}
                aria-labelledby="cancel-subscription-dialog"
              >
                <DialogTitle id="cancel-subscription-dialog">
                  {subscriptionCanBeReactivated
                    ? "Reactivate Subscription"
                    : "Confirm Subscription Cancellation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {subscriptionCanBeReactivated
                      ? "Are you sure you want to reactivate your subscription? You will regain full PRO benefits."
                      : "Are you sure you want to cancel your subscription? You will lose access to the premium features after the current billing period ends."}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseDialog} color="primary">
                    {subscriptionCanBeReactivated
                      ? "Keep Cancelled"
                      : "Keep Subscription"}
                  </Button>
                  <Button
                    onClick={handleCancelSubscription}
                    color="secondary"
                    autoFocus
                  >
                    {subscriptionCanBeReactivated
                      ? "Reactivate Subscription"
                      : "Cancel Subscription"}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <>
              <Typography variant="body1" gutterBottom>
                You don't have an active subscription.
              </Typography>

              {/* Card Information */}
              <Box sx={{ mb: 3, display: "flex", alignItems: "center" }}>
                <LockOutlined sx={{ mr: 1 }} />
                <Typography variant="body1" fontWeight="bold">
                  Card Information
                </Typography>
              </Box>

              {/* Card Number */}
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    padding: "10px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  }}
                >
                  <CardNumberElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#000",
                          "::placeholder": {
                            color: "#888",
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* Expiration Date and CVC */}
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <Box
                  sx={{
                    padding: "10px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    flex: 1,
                  }}
                >
                  <CardExpiryElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: "#000",
                          "::placeholder": {
                            color: "#888",
                          },
                        },
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    padding: "10px",
                    border: "1px solid #c4c4c4",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                    flex: 1,
                  }}
                >
                  <CardCvcElement
                    options={{
                      style: {
                        base: {
                          fontSize: "16px",
                          color: otterTheme.palette.text.primary,
                          "::placeholder": {
                            color: otterTheme.palette.text.secondary,
                          },
                        },
                      },
                    }}
                  />
                </Box>
              </Box>

              {/* Billing Country and ZIP Code */}
              <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
                <TextField
                  label="Billing Country"
                  select
                  fullWidth
                  value={billingCountry}
                  onChange={(e) => setBillingCountry(e.target.value)}
                >
                  <MenuItem value="US">United States</MenuItem>
                  <MenuItem value="CA">Canada</MenuItem>
                  <MenuItem value="UK">United Kingdom</MenuItem>
                </TextField>
                <TextField
                  label="ZIP Code"
                  fullWidth
                  value={zip}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Box>

              {/* Terms Checkbox */}
              <Box sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={agreedToTerms}
                      onChange={(e) => setAgreedToTerms(e.target.checked)}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      sx={{
                        textDecoration: "underline",
                        color: "primary.main",
                      }}
                    >
                      I agree to the Terms & Conditions
                    </Typography>
                  }
                />
              </Box>

              {errorMessage && (
                <Typography color="error" gutterBottom>
                  {errorMessage}
                </Typography>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenSubscribeDialog} // Trigger the confirmation dialog
                disabled={!stripe || isCreating || !agreedToTerms}
                fullWidth
              >
                {isCreating ? <LoadingPage /> : "Subscribe Now"}
              </Button>

              {/* Subscribe Confirmation Dialog */}
              <Dialog
                open={openSubscribeDialog}
                onClose={handleCloseSubscribeDialog}
                fullScreen={fullScreen}
                aria-labelledby="subscribe-confirmation-dialog"
              >
                <DialogTitle id="subscribe-confirmation-dialog">
                  Confirm Subscription
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    You are about to subscribe to Tranzcribe PRO for $19.99 per
                    month. Do you wish to continue?
                  </DialogContentText>
                  {isCreating && <LoadingPage />}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseSubscribeDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubscribe} // Call the subscribe function here
                    color="secondary"
                    autoFocus
                  >
                    Confirm Subscription
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default SubscriptionPage;
