import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate } from "react-router-dom";
import { showErrorToast } from "../../toastUtils";
import { getErrorMessage } from "../../error-helpers/errorMessage";
import { useLogoutMutation } from "../../store/userApi";
import LoadingPage from "../../common/LoadingPage";
import { darkTheme } from "../../theme";

const NavBar: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [logoutMutation, { isLoading: loggingOut }] = useLogoutMutation();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const handleNavigate = (path: string) => {
    navigate(path);
    setDrawerOpen(false); // Close drawer after navigation
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null); // Close the menu
    setLogoutDialogOpen(true); // Open the confirmation dialog
  };

  const handleConfirmLogout = async () => {
    try {
      await logoutMutation().unwrap();
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
    setLogoutDialogOpen(false);
    navigate("/login");
  };

  const handleCancelLogout = () => {
    setLogoutDialogOpen(false);
  };

  // Rearranged drawer items
  const drawerItems = [{ text: "Dashboard", path: "/dashboard" }];
  const textColor = "#ffffff";

  return (
    <>
      <AppBar
        position="static"
        color="primary"
        sx={{
          backgroundColor: darkTheme.palette.background.default,
          color: "#333333",
        }}
      >
        <Toolbar>
          {/*
          <IconButton
            edge="start"
            disabled={true}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon sx={{ color: textColor }} />
          </IconButton>*/}

          <Typography variant="h6" sx={{ flexGrow: 1, color: textColor }}>
            tranzcribe
          </Typography>

          {/* Profile Dropdown */}
          <IconButton color="inherit" onClick={handleMenuOpen}>
            <Avatar sx={{ bgcolor: darkTheme.palette.secondary.main }}>
              <AccountCircleIcon />
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={() => {
                navigate("/feedback");
                handleMenuClose();
              }}
            >
              Feedback
            </MenuItem>
            {/* Rearranged menu items */}
            <MenuItem
              onClick={() => {
                navigate("/tutorial");
                handleMenuClose();
              }}
            >
              Tutorial
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/tos");
                handleMenuClose();
              }}
            >
              Terms & Conditions
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Side Drawer */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            width: 250,
            backgroundColor: darkTheme.palette.background.default,
            color: darkTheme.palette.text.primary,
            height: "100%",
          }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {drawerItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={() => handleNavigate(item.path)}>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      {/* Logout Confirmation Dialog */}
      <Dialog open={logoutDialogOpen} onClose={handleCancelLogout}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
          {loggingOut && <LoadingPage />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelLogout}>Cancel</Button>
          <Button onClick={handleConfirmLogout} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NavBar;
