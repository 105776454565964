import { showErrorToast, showSuccessToast } from "../toastUtils";
import { getErrorMessage } from "../error-helpers/errorMessage";
import removeMarkdown from "remove-markdown"; // Install with: npm install remove-markdown

export const formatElapsedTime = (elapsedTime: number): string => {
  const minutes = Math.floor(elapsedTime / 60); // Calculate full minutes
  const seconds = elapsedTime % 60; // Get the remainder seconds
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds; // Add leading 0 if necessary
  return `${minutes}:${formattedSeconds}`;
};

export const copyToClipboard = async (text: string) => {
  try {
    const contentToCopy = text; // Raw Markdown text
    if (contentToCopy) {
      const removedMarkdown = removeMarkdown(contentToCopy, {
        stripListLeaders: false,
        useImgAltText: true,
      });
      navigator.clipboard
        .writeText(removedMarkdown)
        .then(() => showSuccessToast("Copied to clipboard!"))
        .catch((err) => alert(`Failed to copy: ${err.message}`));
    }
  } catch (e) {
    navigator.clipboard
      .writeText(text)
      .then(() => showSuccessToast("Copied to clipboard!"))
      .catch((err) =>
        showErrorToast(`Failed to copy: ${getErrorMessage(err)}`),
      );
  }
};
