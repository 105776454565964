import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Prompt } from "./userApi";

export interface User {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  isVerified: boolean;
  isDeactivated: boolean;
  phoneNumber?: string;
  stripeSubscriptionId: string | null;
  stripeCustomerId: string;
  hasActiveSubscription: boolean;
  subscribedUntil: string;
  prompts: Prompt[];
}

export interface UserState {
  user: User | null;
}

const initialState: UserState = {
  user: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
    updateUser: (state, action: PayloadAction<Partial<UserState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

// Export actions
export const { setUser, clearUser, updateUser } = userSlice.actions;

// Export the reducer to be included in the store
export default userSlice.reducer;
