import React from "react";
import { Box, Button, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { otterTheme, darkTheme } from "../theme";
import { copyToClipboard } from "../common/commonFuncs";

interface SummarizationDisplayTabProps {
  text: string;
}

const SummarizationDisplayTab: React.FC<SummarizationDisplayTabProps> = ({
  text,
}) => {
  const lightTheme = otterTheme;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: darkTheme.palette.background.default,
        padding: "16px",
      }}
    >
      {/* Text Display Section */}
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          maxWidth: "600px",
          padding: "16px",
          borderRadius: "12px",
          color: lightTheme.palette.common.white,
        }}
      >
        <Box mb={4}>
          <Typography variant={"h6"}>Summary</Typography>
        </Box>

        <ReactMarkdown>{text}</ReactMarkdown>
        <Button
          variant="contained"
          onClick={async () => {
            if (text) {
              await copyToClipboard(text);
            }
          }}
          sx={{
            mt: 4,
            borderColor: "transparent",
            color: lightTheme.palette.common.white,
            backgroundColor: lightTheme.palette.primary.main,
            "&:hover": {
              backgroundColor: lightTheme.palette.primary.light,
              color: lightTheme.palette.common.white,
              borderColor: "transparent",
            },
          }}
          startIcon={<ContentCopyIcon />}
        >
          Copy to Clipboard
        </Button>
      </Box>
    </Box>
  );
};

export default SummarizationDisplayTab;
