import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";

const PasswordReset: React.FC = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);

    // Simulate API call to send password reset email
    await new Promise((resolve) => setTimeout(resolve, 2000));

    setIsSubmitting(false);
    setIsEmailSent(true);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        backgroundColor: "#f4f6f8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      {!isEmailSent ? (
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#fff",
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 4 }}>
            Reset Your Password
          </Typography>
          <TextField
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
            disabled={isSubmitting}
            sx={{ padding: 1.5 }}
          >
            {isSubmitting ? <CircularProgress size={24} /> : "Submit"}
          </Button>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: 400,
            backgroundColor: "#fff",
            padding: 4,
            borderRadius: 2,
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h5" sx={{ marginBottom: 4 }}>
            Email Sent!
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            Please check your email for a password reset link!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setIsEmailSent(false)}
            sx={{ padding: 1.5 }}
          >
            Reset Another Password
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default PasswordReset;
