import { createTheme } from "@mui/material/styles";

// Create a custom theme mimicking Otter.ai's color scheme
export const otterTheme = createTheme({
  palette: {
    mode: "light", // Switch to light mode
    primary: {
      main: "#1976d2", // Light blue (Otter uses blues prominently)
    },
    secondary: {
      main: "#555555", // Neutral gray for secondary elements
    },
    background: {
      default: "#ffffff", // White background for a clean layout
      paper: "#f4f6f8", // Light gray for card and paper surfaces
    },
    text: {
      primary: "#333333", // Darker text color for readability
      secondary: "#666666", // Lighter gray for secondary text
    },
  },
  typography: {
    fontFamily: "'Averta', Arial, sans-serif", // Apply the Averta font family globally
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "25px", // Apply rounded corners globally
          padding: "8px 16px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          padding: "8px 16px",
          color: "#333333", // Darker color for contrast with the background
          "&:hover": {
            backgroundColor: "rgba(25, 118, 210, 0.1)", // Light blue hover effect (Otter-like hover)
          },
          "&:focus": {
            backgroundColor: "rgba(25, 118, 210, 0.2)", // Slightly stronger focus effect
          },
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#c49b40", // Matches the primary color used in PrepareEmailTab
    },
    secondary: {
      main: "#282B2B",
    },
    background: {
      default: "#1B1E1E",
      paper: "#282B2B",
    },
    text: {
      primary: "#ffffff", // White text for readability
      secondary: "#B0B0B0", // Slightly muted secondary text
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "24px", // Matches the rounded buttons in PrepareEmailTab
          padding: "6px 16px", // Same padding as in PrepareEmailTab
          textTransform: "none", // No uppercase transformation
          transition: "background-color 0.3s, border-color 0.3s",
          margin: "4px", // Consistent margin for spacing
          borderColor: "#c49b40", // Matches the border color
          "&:hover": {
            backgroundColor: "#c49c50", // Hover color from PrepareEmailTab
            borderColor: "#c49b40", // Consistent border color
            color: "#000000", // Black text on hover
          },
          "&.Mui-disabled": {
            color: "#c49b40", // Disabled color
            opacity: 0.6,
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          flexWrap: "wrap", // Matches the PrepareEmailTab behavior
          "@media (max-width: 600px)": {
            "& .MuiButton-root": {
              flex: "1 1 100%", // Stack buttons vertically on small screens
            },
          },
        },
      },
    },
  },
});
