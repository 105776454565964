import React from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  Container,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import transcribeLogo from "../../images/tranzcribe-logo-black-text-beta.png";

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {/* Navigation Bar */}
      <AppBar
        position="static"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          padding: { xs: 1, md: 2 },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {/* Left-aligned Logo */}
          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-start" }}>
            <img
              src={transcribeLogo}
              alt={"transcribe-logo"}
              style={{ width: "200px", height: "auto" }}
            />
          </Box>

          {/* Right-aligned Buttons */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: { xs: 1, sm: 0 },
            }}
          >
            <Button
              variant={"contained"}
              color="primary"
              onClick={() => navigate("/login")}
              sx={{
                fontWeight: "500",
                textTransform: "none",
                color: "white",
                marginRight: "16px",
                fontSize: { xs: "0.875rem", sm: "1rem" },
              }}
            >
              Log in
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      <Container>
        <Box
          sx={{
            mt: { xs: 6, md: 10 },
            textAlign: "center",
            maxWidth: "700px",
            margin: "auto",
            px: { xs: 2, md: 0 },
          }}
        >
          <Typography
            variant={isSmallScreen ? "h4" : "h3"}
            gutterBottom
            sx={{ fontWeight: "300", color: "#333" }}
          >
            Speak & let technology work for you ✨
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            gutterBottom
            sx={{
              fontWeight: "400",
              color: "#555",
              marginBottom: "24px",
              fontSize: { xs: "1rem", sm: "1.25rem" },
            }}
          >
            Stop wasting time. Turn your voice into dynamic, AI-driven responses
            with Tranzcribe.
          </Typography>
          {/* Start for Free Button */}
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => navigate("/start")}
            sx={{
              padding: "12px 24px",
              fontWeight: "500",
              backgroundColor: "#1976d2",
              "&:hover": { backgroundColor: "#1565c0" },
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            Join Our Beta
          </Button>
        </Box>
      </Container>
    </div>
  );
};

export default Homepage;
