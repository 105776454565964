import React from "react";
import {
  Box,
  Typography,
  Avatar,
  Chip,
  Paper,
  Divider,
  Stack,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { blue, grey } from "@mui/material/colors";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import LoadingPage from "../../common/LoadingPage";

const Profile = () => {
  const user = useSelector((state: RootState) => state.user.user);

  if (!user) {
    return <LoadingPage />;
  }

  return (
    <Paper
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "48px auto",
        padding: "48px",
        textAlign: "center",
        backgroundColor: "background.default",
        borderRadius: "24px",
        boxShadow: "0 8px 30px rgba(0, 0, 0, 0.12)",
      }}
    >
      {/* Avatar Section */}
      <Avatar
        sx={{
          bgcolor: blue[500],
          width: 140,
          height: 140,
          margin: "0 auto",
          fontSize: "3.5rem",
          textTransform: "uppercase",
          boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.12)",
        }}
      >
        {user.firstName.charAt(0)}
        {user.lastName.charAt(0)}
      </Avatar>

      <Typography
        variant="h4"
        gutterBottom
        sx={{
          marginTop: "24px",
          fontWeight: 700,
          fontSize: "2rem",
          color: grey[900],
        }}
      >
        {user.firstName} {user.lastName}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ fontSize: "1.2rem", marginBottom: "16px" }}
      >
        {user.email}
      </Typography>

      <Divider variant="middle" sx={{ my: 3 }} />

      {/* Role Section */}
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ fontSize: "1.2rem", fontWeight: 600 }}
        >
          Role:
          <span style={{ color: blue[700], marginLeft: "8px" }}>
            {user.role}
          </span>
        </Typography>
      </Box>

      <Divider variant="middle" sx={{ my: 3 }} />

      {/* Verification Section */}
      <Stack direction="column" spacing={2} alignItems="center" mt={3}>
        {user.isVerified ? (
          <Chip
            icon={<CheckCircleIcon sx={{ color: blue[700] }} />}
            label="Verified"
            sx={{
              color: blue[700],
              backgroundColor: blue[50],
              padding: "10px 18px",
              fontSize: "1rem",
              fontWeight: "bold",
              borderRadius: "16px",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          />
        ) : (
          <Typography
            color="error"
            variant="body2"
            sx={{ fontSize: "1.2rem", fontWeight: 600 }}
          >
            Not Verified
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default Profile;
