import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import ReactMarkdown from "react-markdown";
import LoadingPage from "../../common/LoadingPage";
import { showErrorToast } from "../../toastUtils";
import { getErrorMessage } from "../../error-helpers/errorMessage";
import {
  Prompt,
  useCreatePromptDocumentMutation,
  useCreatePromptDocumentRefinementMutation,
  useCreateTranscribedTextPromptMutation,
} from "../../store/userApi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { otterTheme, darkTheme } from "../../theme";
import { copyToClipboard } from "../../common/commonFuncs";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { logAnalyticsEvent } from "../../config/firebase";
import { logPosthogEvent } from "../../common/posthogUtils";

interface PrepareEmailTabProps {
  prompt: Prompt;
  handleDownload: (text: string | null, filename: string) => void;
  inputText: string;
}

const PromptTab: React.FC<PrepareEmailTabProps> = ({
  prompt,
  handleDownload,
  inputText,
}) => {
  const lightTheme = otterTheme; // Updated theme to align with SummarizationTab
  const [text, setText] = useState<string>(inputText);
  const [refinedText, setRefinedText] = useState<string | null>(null);
  const [selectedPrimaryButton, setSelectedPrimaryButton] = useState<
    null | string
  >(null);
  const [selectedSecondaryButton, setSelectedSecondaryButton] = useState<
    null | string
  >(null);
  const user = useSelector((state: RootState) => state.user);

  const [getDocumentPrompt, { isLoading: creatingDocumentPrompt }] =
    useCreatePromptDocumentMutation();
  const [
    getDocumentRefinementPrompt,
    { isLoading: creatingDocumentRefinementPrompt },
  ] = useCreatePromptDocumentRefinementMutation();

  const [
    getTranscribedTextDocument,
    { isLoading: gettingTranscribedTextDocument },
  ] = useCreateTranscribedTextPromptMutation();

  const isLoading =
    creatingDocumentPrompt ||
    creatingDocumentRefinementPrompt ||
    gettingTranscribedTextDocument;

  const getGptResponse = async (primaryPromptId: string) => {
    try {
      logAnalyticsEvent("getDocumentPrompt", {
        primaryPromptId,
        funcName: "getGptResponse",
      });
      logPosthogEvent(
        "getDocumentPrompt",
        { primaryPromptId, funcName: "getGptResponse" },
        user.user?.email,
      );
      const response = await getDocumentPrompt({
        id: prompt.id,
        primaryPromptId,
        text: inputText,
      }).unwrap();
      setRefinedText(null);
      setText(response.gptTextResponse);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  };

  const handlePrimaryButtonClick = (primaryPromptId: string) => {
    if (isLoading) return;
    setSelectedPrimaryButton(primaryPromptId);
    setSelectedSecondaryButton(null);
    getGptResponse(primaryPromptId);
  };

  const handleSecondaryButtonClick = async (secondaryPromptId: string) => {
    if (isLoading) return;
    setSelectedSecondaryButton(secondaryPromptId);
    try {
      logAnalyticsEvent("getDocumentRefinementPrompt", {
        secondaryPromptId,
        funcName: "handleSecondaryButtonClick",
      });
      logPosthogEvent(
        "getDocumentRefinementPrompt",
        { secondaryPromptId, funcName: "handleSecondaryButtonClick" },
        user.user?.email,
      );
      const response = await getDocumentRefinementPrompt({
        id: prompt.id,
        text,
        secondaryPromptId,
      }).unwrap();
      setRefinedText(response.gptTextResponse);
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  };

  const handleInitialTranscribedTextPrompt = async () => {
    try {
      logAnalyticsEvent("handleInitialTranscribedTextPrompt", {
        funcName: "handleInitialTranscribedTextPrompt",
      });
      logPosthogEvent(
        "handleInitialTranscribedTextPrompt",
        { funcName: "handleInitialTranscribedTextPrompt" },
        user.user?.email,
      );
      const response = await getTranscribedTextDocument({
        id: prompt.id,
        text: inputText,
      }).unwrap();
      setText(response.gptTextResponse);
    } catch (error) {
      showErrorToast(`Failed to get document ${getErrorMessage(error)}`);
    }
  };

  useEffect(() => {
    handleInitialTranscribedTextPrompt();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: darkTheme.palette.background.default,
        padding: "16px",
      }}
    >
      {/* Primary Button Group */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "600px",
          width: "100%",
          padding: "16px",
        }}
      >
        <ButtonGroup
          disabled={isLoading}
          variant="outlined"
          aria-label="primary button group"
          sx={{
            mb: 2,
            flexWrap: "wrap",
            "& .MuiButton-root": {
              borderColor: "#1976d2",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "6px 16px",
              textTransform: "none",
              transition: "background-color 0.3s, border-color 0.3s",
              margin: "4px",
              "&:hover": {
                backgroundColor: lightTheme.palette.primary.light,
                color: lightTheme.palette.common.white,
                borderColor: "transparent",
              },
              "&.Mui-disabled": {
                color: "#ffffff",
                opacity: 0.6,
                borderColor: "#1976d2",
              },
            },
          }}
        >
          {prompt.primaryPrompts.map((primaryPrompt) => (
            <Button
              key={primaryPrompt.id}
              onClick={() => handlePrimaryButtonClick(primaryPrompt.id)}
              sx={{
                borderColor: "transparent",
                color:
                  selectedPrimaryButton === primaryPrompt.id
                    ? lightTheme.palette.common.white
                    : lightTheme.palette.primary.main,
                backgroundColor:
                  selectedPrimaryButton === primaryPrompt.id
                    ? lightTheme.palette.primary.main
                    : "transparent",
                margin: "4px",
                "&:hover": {
                  backgroundColor: lightTheme.palette.primary.light,
                  color: lightTheme.palette.common.white,
                  borderColor: "transparent",
                },
                "&.Mui-disabled": {
                  color: "#ffffff",
                  opacity: 0.6,
                  borderColor: "#1976d2",
                },
              }}
            >
              {primaryPrompt.title}
            </Button>
          ))}
        </ButtonGroup>

        <ButtonGroup
          disabled={isLoading}
          variant="outlined"
          aria-label="secondary button group"
          sx={{
            flexWrap: "wrap",
            mb: 2,
            flexDirection: "row",

            "& .MuiButton-root": {
              borderColor: "#1976d2",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "6px 16px",
              textTransform: "none",
              transition: "background-color 0.3s, border-color 0.3s",
              margin: "4px", // Adjust margin for wrapping
              "&:hover": {
                backgroundColor: lightTheme.palette.primary.light,
                color: lightTheme.palette.common.white,
                borderColor: "transparent",
              },
              "&.Mui-disabled": {
                color: "#ffffff",
                opacity: 0.6,
                borderColor: "#1976d2",
              },
            },
          }}
        >
          {/* Secondary Button Group */}
          {selectedPrimaryButton &&
            prompt.primaryPrompts
              .find(
                (primaryPrompt) => primaryPrompt.id === selectedPrimaryButton,
              )
              ?.secondaryPrompts.map((secondaryPrompt) => (
                <Button
                  onClick={() => handleSecondaryButtonClick(secondaryPrompt.id)}
                  sx={{
                    borderColor: "transparent",
                    color:
                      selectedSecondaryButton === secondaryPrompt.id
                        ? lightTheme.palette.common.white
                        : lightTheme.palette.primary.main,
                    backgroundColor:
                      selectedSecondaryButton === secondaryPrompt.id
                        ? lightTheme.palette.primary.main
                        : "transparent",
                    margin: "4px",
                    "&:hover": {
                      backgroundColor: lightTheme.palette.primary.light,
                      color: lightTheme.palette.common.white,
                      borderColor: "transparent",
                    },
                    "&.Mui-disabled": {
                      color: "#ffffff",
                      opacity: 0.6,
                      borderColor: "#1976d2",
                    },
                  }}
                >
                  {secondaryPrompt.title}
                </Button>
              ))}
        </ButtonGroup>
      </Box>

      {/* Text Display Section */}
      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          maxWidth: "600px",
          padding: "16px",
          borderRadius: "12px",
          color: lightTheme.palette.common.white,
        }}
      >
        {isLoading ? (
          <LoadingPage />
        ) : (
          <ReactMarkdown>{refinedText ?? text}</ReactMarkdown>
        )}

        <Box sx={{ display: "flex", gap: "16px", marginTop: "16px" }}>
          <Button
            variant="contained"
            onClick={async () => {
              const contentToCopy = refinedText ?? text;
              if (contentToCopy) {
                await copyToClipboard(contentToCopy);
              }
            }}
            sx={{
              backgroundColor: lightTheme.palette.primary.main,
              color: lightTheme.palette.common.white,
              "&:hover": { backgroundColor: lightTheme.palette.primary.light },
            }}
            startIcon={<ContentCopyIcon />}
          >
            Copy to Clipboard
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PromptTab;
