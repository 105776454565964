import React from "react";
import { Box, ThemeProvider } from "@mui/material";
import { darkTheme } from "../../theme";

import { getWhiteTextBetaLogo } from "../../common/commonWidgets";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

const TutorialPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          color: "#ffffff",
          height: "95vh",
          backgroundColor: darkTheme.palette.background.default,
          textAlign: "center",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems={"center"}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            {getWhiteTextBetaLogo()}
          </Box>

          <ReactPlayer
            url="https://vimeo.com/1037983956/8a715f1482"
            width="100%"
            height="50vh"
            controls
            playing={false}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TutorialPage;
