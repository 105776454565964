import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HistoryIcon from "@mui/icons-material/History";

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <Container maxWidth="md" sx={{ mt: 5 }}>
      <Typography variant="h4" gutterBottom align="center">
        Settings
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mt: 3 }}>
        {/* Update Profile */}
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <AccountCircleIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Update Profile
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Change your account details and profile information.
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => handleNavigation("/profile")}
              color="primary"
            >
              Update
            </Button>
          </CardContent>
        </Card>

        <Divider />

        {/* Manage Subscription */}
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <CreditCardIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Manage Subscription
              </Typography>
              <Typography variant="body2" color="textSecondary">
                View or change your subscription plan.
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => handleNavigation("/manage/subscription")}
              color="primary"
            >
              Manage
            </Button>
          </CardContent>
        </Card>

        <Divider />

        {/* Billing History */}
        <Card sx={{ boxShadow: 3 }}>
          <CardContent sx={{ display: "flex", alignItems: "center" }}>
            <HistoryIcon sx={{ fontSize: 40, mr: 2 }} />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div">
                Billing History
              </Typography>
              <Typography variant="body2" color="textSecondary">
                View your payment history and invoices.
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => handleNavigation("/view/billing-history")}
              color="primary"
            >
              View
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default SettingsPage;
