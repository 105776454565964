import React, { useEffect, useState } from "react";
import { Container, Typography, Box, Stack } from "@mui/material";
import { keyframes } from "@mui/system";

// Keyframes for spinner animation
const pulse = keyframes`
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.7;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
`;

// Keyframes for text fade-in animation
const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const phrases = [
  "Hang on a second...",
  "Just a second...",
  "We're almost there...",
  "Loading something awesome...",
  "Preparing your experience...",
];

const LoadingPage = () => {
  const [, setCurrentPhrase] = useState(
    phrases[Math.floor(Math.random() * phrases.length)],
  );

  useEffect(() => {
    const pickRandomPhrase = () => {
      const randomPhrase = phrases[Math.floor(Math.random() * phrases.length)];
      setCurrentPhrase(randomPhrase);
    };

    // Set interval to pick a new phrase every 3 seconds
    const phraseInterval = setInterval(pickRandomPhrase, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(phraseInterval);
  }, []);

  return (
    <Container
      sx={{
        textAlign: "center",
        paddingTop: "100px",
        animation: `${fadeIn} 1s ease-in-out`,
      }}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
      >
        {/* Custom Pulse Spinner */}
        <Box
          sx={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            background: "linear-gradient(135deg, #7f2fd3, #24b9f5)",
            animation: `${pulse} 1.5s infinite ease-in-out`,
          }}
        ></Box>

        {/* Loading Phrase */}
        <Typography
          variant="h6"
          sx={{
            marginTop: "20px",
            color: "#444",
            fontWeight: "500",
            animation: `${fadeIn} 1s ease-in-out`,
          }}
        ></Typography>
      </Stack>
    </Container>
  );
};

export default LoadingPage;
