import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { getBlackTextBetaLogo } from "../../common/commonWidgets";
import {
  useSearchParams,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import { useCreateUserMutation, UserCreateArgs } from "../../store/userApi";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { getErrorMessage } from "../../error-helpers/errorMessage";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userSlice";
import LoadingPage from "../../common/LoadingPage";

const SignUpPage: React.FC = () => {
  const [params] = useSearchParams();
  let planType = params.get("PLAN");
  if (!planType) {
    planType = "FREE";
  }
  const [activeStep, setActiveStep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [createUser, { isLoading: creatingUser }] = useCreateUserMutation();

  const steps = ["Enter your email", "Create a password"];

  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleNext = () => {
    if (activeStep === 0) {
      if (!emailPattern.test(email)) {
        setEmailError(true);
        return;
      }
      setEmailError(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else if (activeStep === 1) {
      if (!termsAccepted) {
        setTermsError(true);
        return;
      }
      setTermsError(false);
      if (password.length < 6) {
        setPasswordError(true);
        return;
      }
      setPasswordError(false);
      setOpenDialog(true);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmSignUp = async () => {
    try {
      const userCreateArgs: UserCreateArgs = {
        email,
        password,
        plan: planType!,
      };
      const newUser = await createUser(userCreateArgs).unwrap();
      dispatch(setUser(newUser));
      setOpenDialog(false);
      showSuccessToast("Successfully created your account!");
      navigate("/dashboard");
    } catch (error) {
      showErrorToast(getErrorMessage(error));
      setOpenDialog(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f4f6f8",
        width: "100%",
        height: "100vh",
        padding: 0,
      }}
    >
      {getBlackTextBetaLogo()}
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          backgroundColor: "#ffffff",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          textAlign: "center",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 4 }}>
          Sign Up for the Beta
        </Typography>

        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{ marginBottom: 4 }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box>
            <TextField
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
              helperText={
                emailError ? "Please enter a valid email address" : ""
              }
              sx={{ marginBottom: 2 }}
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleNext}
              sx={{ padding: 1.5 }}
            >
              Next
            </Button>
          </Box>
        )}

        {activeStep === 1 && (
          <Box>
            <TextField
              label="Password"
              type="password"
              fullWidth
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError}
              helperText={
                passwordError ? "Password must be at least 6 characters" : ""
              }
              sx={{ marginBottom: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  color="primary"
                />
              }
              label={
                <Typography variant="body2">
                  I agree to the{" "}
                  <Link href="/terms" target="_blank" underline="hover">
                    Terms & Conditions
                  </Link>
                </Typography>
              }
            />
            {termsError && (
              <Typography
                variant="caption"
                color="error"
                sx={{ display: "block", marginTop: 1 }}
              >
                You must accept the Terms & Conditions to proceed.
              </Typography>
            )}
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                variant="outlined"
                onClick={handleBack}
                sx={{ textTransform: "none" }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={{ textTransform: "none" }}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
        )}

        <Typography
          variant="body2"
          sx={{ marginTop: 3, color: "textSecondary" }}
        >
          Already have an account?{" "}
          <Link component={RouterLink} to="/login" underline="hover">
            Login now!
          </Link>
        </Typography>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Sign Up</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to sign up with this information?
            </DialogContentText>
            <Typography>Email: {email}</Typography>
            {creatingUser && <LoadingPage />}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleConfirmSignUp}
              color="primary"
              variant="contained"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default SignUpPage;
