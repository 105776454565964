import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  IconButton,
  ThemeProvider,
  Button,
} from "@mui/material";
import { darkTheme } from "../../theme";
import { useCreateFeedbackMutation } from "../../store/userApi";
import { useProcessAudioMutation } from "../../store/apiSlice";
import { showErrorToast, showSuccessToast } from "../../toastUtils";
import { getWhiteTextBetaLogo } from "../../common/commonWidgets";
import { useNavigate } from "react-router-dom";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LoadingPage from "../../common/LoadingPage";
import { formatElapsedTime } from "../../common/commonFuncs";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import {
  countdownBoxStyles,
  countdownTypographyStyles,
  tabStyles,
} from "../styles";

const FeedbackPage: React.FC = () => {
  const navigate = useNavigate();

  // Audio recording state variables
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [showCompletedIcon, setShowCompletedIcon] = useState(false);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioBlobRef = useRef<Blob | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);
  const elapsedTimeRef = useRef(0);
  const user = useSelector((state: RootState) => state.user);

  const [processAudio, { isLoading: isProcessingAudio }] =
    useProcessAudioMutation();
  const [createFeedback, { isLoading: isSubmittingFeedback }] =
    useCreateFeedbackMutation();

  // Start Countdown before recording
  const startCountdown = () => {
    setCountdown(3);
  };

  useEffect(() => {
    if (countdown === null) return;

    if (countdown > 0) {
      const timer = setTimeout(
        () => setCountdown((prev) => (prev ?? 0) - 1),
        1000,
      );
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      startRecording();
      setCountdown(null);
    }
  }, [countdown]);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;

      let mimeType: string = "";
      if (MediaRecorder.isTypeSupported("audio/webm")) {
        mimeType = "audio/webm";
      } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        mimeType = "audio/mp4";
      } else if (MediaRecorder.isTypeSupported("audio/mpeg")) {
        mimeType = "audio/mpeg";
      } else {
        showErrorToast("No supported audio format found for recording.");
        return;
      }

      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
        audioBlobRef.current = audioBlob;

        audioChunksRef.current = [];

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }

        clearRecordingTimer();
        handleProcessAudioAndSubmitFeedback();
        setShowCompletedIcon(true);
        setTimeout(() => setShowCompletedIcon(false), 2000);
        setHasRecorded(true);
        setIsRecording(false);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setElapsedTime(0);
      elapsedTimeRef.current = 0;
      startRecordingTimer();
    } catch (error) {
      showErrorToast(
        `Could not access the microphone. Please check your permissions.`,
      );
    }
  }, []);

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      clearRecordingTimer();
      setIsRecording(false);
    }
  };

  const startRecordingTimer = () => {
    recordingTimerRef.current = setInterval(() => {
      elapsedTimeRef.current += 1;
      setElapsedTime(elapsedTimeRef.current);
    }, 1000);
  };

  const clearRecordingTimer = () => {
    if (recordingTimerRef.current) {
      clearInterval(recordingTimerRef.current);
      recordingTimerRef.current = null;
    }
  };

  const handleProcessAudioAndSubmitFeedback = useCallback(async () => {
    if (!audioBlobRef.current) {
      showErrorToast("No audio recorded. Please record audio first.");
      return;
    }
    const formData = new FormData();
    formData.append("audio", audioBlobRef.current, "recording");
    formData.append("language", "en");
    formData.append("videoLength", elapsedTimeRef.current.toString());
    try {
      const response = await processAudio(formData).unwrap();
      // Automatically submit the feedback
      await createFeedback({
        description: response.transcribedText,
        title: `${user.user?.email} new feedback`,
      }).unwrap();
      showSuccessToast("Feedback submitted successfully!");
      navigate("/dashboard"); // Redirect after successful submission
    } catch (error) {
      console.error("Error processing audio or submitting feedback:", error);
      showErrorToast("Failed to submit feedback. Please try again.");
    }
  }, [processAudio, createFeedback, navigate]);

  const handleMicClick = () => {
    if (hasRecorded) {
      setHasRecorded(false);
      startCountdown();
    } else {
      startCountdown();
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        sx={{
          height: "95vh",
          color: "#ffffff",
          backgroundColor: darkTheme.palette.background.default,
          textAlign: "center",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems={"center"}>
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/dashboard")}
          >
            {getWhiteTextBetaLogo()}
          </Box>

          {!hasRecorded && countdown === null && (
            <Box mt={20} mb={20} width={"100%"} maxWidth={"500px"}>
              {isRecording ? (
                <IconButton
                  color="secondary"
                  onClick={stopRecording}
                  sx={{
                    mb: 2,
                    color: "#FFFFFF",
                    transform: "scale(5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    width: "fit-content",
                    animation: "pulsate 2s infinite",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "@keyframes pulsate": {
                      "0%": {
                        transform: "scale(5)",
                        color: "#FFFFFF",
                      },
                      "50%": {
                        transform: "scale(5.1)",
                        color: "red",
                      },
                      "100%": {
                        transform: "scale(5)",
                        color: "#FFFFFF",
                      },
                    },
                  }}
                >
                  <StopIcon fontSize="large" />
                </IconButton>
              ) : (
                <Button color="primary" onClick={handleMicClick} sx={tabStyles}>
                  <Box color={"#ffffff"}>
                    <MicIcon />
                    <Typography>Give feedback</Typography>
                  </Box>
                </Button>
              )}
            </Box>
          )}

          {countdown !== null ? (
            <Box
              display={"flex"}
              flexDirection={"column"}
              sx={countdownBoxStyles}
              onClick={() => {
                setCountdown(null);
              }}
            >
              <Typography variant="h1" sx={countdownTypographyStyles}>
                {countdown}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  fontWeight: "medium",
                  mt: 1,
                }}
              >
                Stop
              </Typography>
            </Box>
          ) : isRecording ? (
            <Typography
              variant="h6"
              sx={{ mt: 1, color: "red", fontSize: "32px" }}
            >
              {formatElapsedTime(elapsedTime)}
            </Typography>
          ) : showCompletedIcon ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 1 }}
            >
              <ThumbUpIcon color="success" sx={{ mr: 1 }} />
              <Typography variant="h6" sx={{ color: "green" }}>
                Completed!
              </Typography>
            </Box>
          ) : null}
          {/* Loading Indicator */}
          {(isProcessingAudio || isSubmittingFeedback) && <LoadingPage />}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FeedbackPage;
