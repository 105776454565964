import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import AppThemeProvider from "./AppThemeProvider"; // Import the configured Redux store
import { PostHogProvider } from "posthog-js/react";
import store from "./store/store";
import "./index.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY!, {});

root.render(
  <React.StrictMode>
    <AppThemeProvider>
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        <Provider store={store}>
          <Elements stripe={stripePromise}>
            {/* Wrap your app with BrowserRouter to enable routing */}
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Elements>
        </Provider>
      </PostHogProvider>
    </AppThemeProvider>
  </React.StrictMode>,
);
