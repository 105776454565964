export const getErrorMessage = (error: any): string => {
  if (error instanceof Error) {
    return error.message;
  }

  if (typeof error === "string") {
    return error;
  }

  if (typeof error === "object") {
    // Check for Redux Toolkit query error
    if ("status" in error) {
      // FetchBaseQueryError
      if ("error" in error) {
        return typeof error.error === "string"
          ? error.error
          : JSON.stringify(error.error);
      }
      // SerializedError or the specific structure you provided
      if ("data" in error) {
        if (typeof error.data === "string") {
          return error.data;
        }
        if (typeof error.data === "object") {
          // Check for data.error.errors array
          if (
            error.data.error &&
            Array.isArray(error.data.error.errors) &&
            error.data.error.errors.length > 0
          ) {
            return error.data.error.errors[0].message;
          }
          if ("message" in error.data) {
            return error.data.message as string;
          }
          if ("error" in error.data && typeof error.data.error === "string") {
            return error.data.error;
          }
        }
      }
    }

    // Check for the specific Stripe error structure
    if (error.error && error.error.raw && error.error.raw.message) {
      return `${error.message}: ${error.error.raw.message}`;
    }

    // Check for message at the top level
    if (error.message) {
      return error.message;
    }

    // Check for nested error object
    if (error.error) {
      if (typeof error.error === "string") {
        return error.error;
      }
      if (error.error.message) {
        return error.error.message;
      }
      if (error.error.raw && error.error.raw.message) {
        return error.error.raw.message;
      }
      // Check for error.errors array
      if (Array.isArray(error.error.errors) && error.error.errors.length > 0) {
        return error.error.errors[0].message;
      }
    }

    // Check for API error response format
    if (error.status && error.statusText) {
      return `Error ${error.status}: ${error.statusText}`;
    }

    // Check for Sequelize error name and message
    if (error.name && error.message) {
      return `${error.name}: ${error.message}`;
    }
  }

  return "An unknown error occurred";
};
