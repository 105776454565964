// Define the API using createApi
import { baseQuery } from "./defaultBaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";
import { User } from "./userSlice";
import { GPTResponse } from "./apiSlice";

export interface UserCreateArgs {
  email: string;
  password: string;
  plan: string;
}

export interface UserSubscriptionCreateArgs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  paymentMethodId: string;
}

export interface SubscriptionDetails {
  planId: string;
  planNickname: string;
  status: string;
  currentPeriodEnd: Date;
  currentPeriodStart: Date;
}

export interface BillingHistoryItem {
  id: string;
  amount: number;
  currency: string;
  created: number; // Timestamp
  status: string;
  description: string;
}

export interface PromptPrimaryRefinement {
  id: string;
  title: string;
  promptContent: string;
  secondaryPrompts: PromptSecondaryRefinement[];
}

export interface PromptSecondaryRefinement {
  id: string;
  title: string;
  promptContent: string;
}

export interface Prompt {
  id: string;
  title: string;
  promptContent: string;
  enabled: boolean;
  userId: string;
  primaryPrompts: PromptPrimaryRefinement[];
}

export interface TranscribedTextPromptCreateArgs {
  id: string;
  text: string;
}

export interface PrimaryPromptCreateArgs {
  id: string;
  primaryPromptId: string;
  text: string;
}

export interface SecondaryPromptCreateArgs {
  id: string;
  secondaryPromptId: string;
  text: string;
}

const tagTypes = ["userTagTypes"];

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQuery,
  tagTypes,
  endpoints: (builder) => ({
    // Create user endpoint
    createUser: builder.mutation<User, UserCreateArgs>({
      query: (user) => ({
        url: "/users/create",
        method: "POST",
        body: user,
      }),
      invalidatesTags: tagTypes,
    }),
    createUserWithSubscription: builder.mutation<
      User,
      UserSubscriptionCreateArgs
    >({
      query: (user) => ({
        url: "/users/create/with-subscription",
        method: "POST",
        body: user,
      }),
      invalidatesTags: tagTypes,
    }),
    login: builder.mutation<User, { email: string; password: string }>({
      query: (credentials) => ({
        url: "/users/login",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: tagTypes,
    }),
    // Logout endpoint
    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/users/logout",
        method: "POST",
      }),
      invalidatesTags: tagTypes,
    }),
    getUserAttributes: builder.query<User, void>({
      query: () => ({
        url: "/users/attrs",
      }),
      providesTags: tagTypes,
    }),
    // Get subscription details endpoint
    getSubscriptionDetails: builder.query<SubscriptionDetails, void>({
      query: () => ({
        url: "/users/subscription/details",
        method: "GET",
      }),
      providesTags: tagTypes,
    }),

    // Cancel subscription endpoint
    cancelSubscription: builder.mutation<void, void>({
      query: () => ({
        url: "/users/subscription/cancel",
        method: "POST",
      }),
      invalidatesTags: tagTypes,
    }),

    // Reactivate subscription endpoint
    reactivateSubscription: builder.mutation<void, void>({
      query: () => ({
        url: "/users/subscription/reactivate",
        method: "POST",
      }),
      invalidatesTags: tagTypes,
    }),

    // Create subscription endpoint
    createSubscription: builder.mutation<User, { paymentMethodId: string }>({
      query: (body) => ({
        url: "/users/subscription/create",
        method: "POST",
        body,
      }),
      invalidatesTags: tagTypes,
    }),
    getBillingHistory: builder.query<BillingHistoryItem[], void>({
      query: () => ({
        url: "/users/billing/all",
        method: "GET",
      }),
      providesTags: tagTypes,
    }),
    getCustomPrompts: builder.query<Prompt[], void>({
      query: () => "/prompts",
      providesTags: tagTypes,
    }),
    createCustomPrompt: builder.mutation<void, { prompt: string }>({
      query: (newPrompt) => ({
        url: "/prompts",
        method: "POST",
        body: newPrompt,
      }),
      invalidatesTags: tagTypes,
    }),
    createPromptDocument: builder.mutation<
      GPTResponse,
      PrimaryPromptCreateArgs
    >({
      query: (newPrompt) => ({
        url: "/prompts/create",
        method: "POST",
        body: newPrompt,
      }),
      invalidatesTags: tagTypes,
    }),
    createTranscribedTextPrompt: builder.mutation<
      GPTResponse,
      TranscribedTextPromptCreateArgs
    >({
      query: (newPrompt) => ({
        url: "/prompts/create/initial",
        method: "POST",
        body: newPrompt,
      }),
      invalidatesTags: tagTypes,
    }),
    createPromptDocumentRefinement: builder.mutation<
      GPTResponse,
      SecondaryPromptCreateArgs
    >({
      query: (newPrompt) => ({
        url: "/prompts/create/refine",
        method: "POST",
        body: newPrompt,
      }),
      invalidatesTags: tagTypes,
    }),

    // New feedback endpoint
    createFeedback: builder.mutation<
      { success: boolean },
      { title: string; description: string }
    >({
      query: (feedback) => ({
        url: "/users/feedback/create",
        method: "POST",
        body: feedback,
      }),
    }),
  }),
});

// Export hooks for the API calls
export const {
  useCreateUserMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetUserAttributesQuery,
  useCreateUserWithSubscriptionMutation,
  useGetSubscriptionDetailsQuery,
  useCancelSubscriptionMutation,
  useReactivateSubscriptionMutation,
  useCreateSubscriptionMutation,
  useGetBillingHistoryQuery,
  useCreateCustomPromptMutation,
  useGetCustomPromptsQuery,
  useCreatePromptDocumentMutation,
  useCreatePromptDocumentRefinementMutation,
  useCreateTranscribedTextPromptMutation,
  useCreateFeedbackMutation,
} = userApi;
