import React, { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { getBlackTextBetaLogo } from "../../common/commonWidgets";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../../store/userApi";
import { showErrorToast } from "../../toastUtils";
import { getErrorMessage } from "../../error-helpers/errorMessage";
import LoadingPage from "../../common/LoadingPage";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/userSlice";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState(""); // State to store email
  const [password, setPassword] = useState(""); // State to store password

  const [userLogin, { isLoading: userLoggingIn }] = useLoginMutation();
  const dispatch = useDispatch();

  // Handle login button click
  const handleLoginButtonClicked = async () => {
    try {
      // Call userLogin mutation with email and password
      const user = await userLogin({ email, password }).unwrap();
      dispatch(setUser(user));
      navigate("/dashboard");
    } catch (error) {
      showErrorToast(getErrorMessage(error));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f4f6f8",
      }}
    >
      {/* Logo at the top */}
      {getBlackTextBetaLogo()}

      {/* Login Card */}
      <Box
        sx={{
          width: "100%",
          maxWidth: 400,
          backgroundColor: "#fff",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
        }}
      >
        <Typography variant="h5" sx={{ textAlign: "center", marginBottom: 4 }}>
          Login to Tranzcribe
        </Typography>

        {/* Google Sign In Button
        <Button
          fullWidth
          variant="outlined"
          startIcon={<GoogleIcon />}
          sx={{ marginBottom: 2 }}
          onClick={() => console.log("Google Sign Up")}
        >
          Sign in with Google
        </Button>

        <Divider sx={{ marginY: 2 }}>OR</Divider>*/}
        {/* Email Field */}
        <TextField
          label="Email"
          type="email"
          fullWidth
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)} // Set email in state
          sx={{ marginBottom: 2 }}
        />

        {/* Password Field */}
        <TextField
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={password}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleLoginButtonClicked();
            }
          }}
          onChange={(e) => setPassword(e.target.value)} // Set password in state
          sx={{ marginBottom: 2 }}
        />

        {/* Forgot Password Link */}
        <Typography
          variant="body2"
          color="primary"
          sx={{ textAlign: "right", marginBottom: 2, cursor: "pointer" }}
          onClick={() => {
            navigate("/password-reset");
          }}
        >
          Forgot password?
        </Typography>

        {/* Login Button */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ padding: 1.5, fontWeight: "bold" }}
          onClick={handleLoginButtonClicked} // Call login function on click
          disabled={userLoggingIn} // Disable button while logging in
        >
          Log in
        </Button>
        {userLoggingIn && <LoadingPage />}

        {/* Sign Up Prompt */}
        <Typography variant="body2" sx={{ marginTop: 2, textAlign: "center" }}>
          Don’t have an account?{" "}
          <Typography
            component="span"
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer", fontWeight: "bold" }}
            onClick={() => {
              navigate("/start");
            }}
          >
            Sign Up
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
