// Import the PostHog library
import posthog from "posthog-js";
import { getBrowserInfo } from "../config/firebase";

// Initialize PostHog
posthog.init("your-api-key", {
  api_host: "https://app.posthog.com", // Replace with your PostHog host if self-hosted
});

/**
 * Logs an event to PostHog.
 * @param eventName - The name of the event to log.
 * @param properties - (Optional) Additional properties to include with the event.
 * @param distinctId - (Optional) A unique identifier for the user associated with the event.
 */
export function logPosthogEvent(
  eventName: string,
  properties: Record<string, any> = {},
  distinctId?: string,
): void {
  if (distinctId) {
    // Identify the user if a distinctId is provided
    posthog.identify(distinctId);
  }

  // Log the event
  posthog.capture(eventName, { ...getBrowserInfo(), ...properties });

  console.log(`Event logged: ${eventName}`, properties);
}
