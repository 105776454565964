import React from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { otterTheme } from "./theme"; // Import the custom dark theme

const AppThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ThemeProvider theme={otterTheme}>
      <CssBaseline />{" "}
      {/* Normalizes styles and applies the dark theme globally */}
      {children}
    </ThemeProvider>
  );
};

export default AppThemeProvider;
